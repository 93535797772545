/* Small phones: from 0 to 480px */
@media only screen and (max-width: 480px) {

  #loading-text {
    font-size: 4vw;
    padding: 0% 0% 4% 0%;
  }

  #loader {
    height: 17%;
    width: 17%;
    display: flex;
    justify-content: center;
  }

  .tabs {
    font-size: 3.5vw;
    width: 90%;
  }

  .icons {
    width: 14vw;
    margin-left: 5px;
  }

  #icons-box {
    padding-bottom: 12vh;
  }

  .paint {
    padding-bottom: 20vh;
  }

  .about-text, .contact-message {
    font-size: 3.5vw;
    line-height: 18px;
  }

  .about-text p {
    margin-bottom: 2.5%;
  }

  .profile-pic {
    width: 80%;
  }

  .app-description, .app-img-text {
    font-size: 2.5vw;
    line-height: 1.5em;
  }

  .app-description {
    letter-spacing: 0.1px;
  }

  .view-repo, .visit-site {
    font-size: 2vw;
    padding: 2%;
  }

  .education-title, .skills-title, .applications-title, .experience-title, .others-title, .education, .skills, .applications, .experience, .others, #download-btn {
    font-size: 3vw;
  }

  #download-btn, .send-btn {
    padding: 2%;
  }

  .contact-form div {
    padding-top: 3%;
  }

  .sticky {
    padding: 3% 0% 3% 0%;
  }

  .nav {
    padding: 3% 0% 3% 0%;
  }

}

