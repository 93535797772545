/* Big tablet to 1200px (widths smaller than the 1140px row) */
@media only screen and (max-width: 1200px) {

  .about-details {
    flex-direction: column;
    width: 90%;
  }

  .about-text {
    padding-top: 20px;
    width: 100%;
  }

  .profile-pic {
    width: 429.5px;
  }

  .gym-app-img, .app-description, .app-img-view, .app-img-view-sdc, .resume-details {
    width: 90%;
  }

  .app-title {
    flex-direction: column;
  }

}

/* Small tablet to big tablet: from 768px to 1023px */
@media only screen and (max-width: 1023px) {

  #name {
    font-size: 50px;
  }
  
  #role {
    font-size: 18px;
  }

  .atini-logo {
   width: 4rem;
   margin-top: 0.5rem;
  }

  .tabs {
    font-size: 15px;
    width: 400px;
  }

  .icons {
    width: 5vw;
  }

}

/* Small phones to small tablets: from 481px to 767px */
@media only screen and (max-width: 767px) {

  #name {
    font-size: 9vw;
  }

  #role {
    font-size: 4vw;
  }

  .atini-logo {
    width: 3.5rem;
    margin-top: 0px;
  }

  .about-text {
    font-size: 14px;
    line-height: 20px;
  }

  .profile-pic {
    width: 80%;
  }

  .about-text p {
    margin-bottom: 2%;
  }

  .app-description, .app-img-text {
    font-size: 12px;
    line-height: 1.5em;
  }

  .view-repo, .visit-site {
    font-size: 10px;
    padding: 8px;
  }

  #college-title, #college-details {
    line-height: 1.4em;
    flex-direction: column;
  }

  .contact-form, #name-input, #email-input, #message-input {
    width: 90%;
    padding: 1.5%;
  }

  .contact-form div {
    padding-top: 2.5%;
  }
}